import React from "react";
import "../scss/main.scss";
import {Button, defaultTheme, Provider, Flex, Heading, Text} from "@adobe/react-spectrum";
import {navigate} from "@reach/router";
import DeviceRepository from "../repositories/DeviceRepository";
import AnalyticsRepository from "../repositories/AnalyticsRepository";

export default class GetStarted extends React.Component{

    constructor(props) {
        super(props);

        this.state = {
            infoStyle: {
                height: 0,
                opacity: 0,
            },
            height: DeviceRepository.getHeight()
        }

        this.main = React.createRef();
        this.instructions = React.createRef();

        this.handleDownload = this.handleDownload.bind(this);
        this.afterOpenClose = this.afterOpenClose.bind(this);
        this.handleResize = this.handleResize.bind(this);
    }

    afterOpenClose() {
        DeviceRepository.postHeightToParent();
    }

    getDownloadButton() {
        if(DeviceRepository.isMobile()) {
            return (
                <Heading level={4}>Please visit this site on a desktop browser to download assets.</Heading>
            )
        } else {
            if(DeviceRepository.isIE()) {
                return (
                    <button className="fallback__button-cta" onClick={this.handleDownload} disabled={DeviceRepository.isExpired()}>
                        Download ZIP
                    </button>
                );
            } else {
                return (
                    <Button variant="cta" onPress={this.handleDownload} isDisabled={DeviceRepository.isExpired() || this.props.isDisabled}>
                        Download ZIP
                    </Button>
                );
            }
        }
    }

    getFinalPanel() {
        if(DeviceRepository.isMobile()) {
            return (
                <div className="GetStarted__instruction-panel e">
                    <Flex direction="column" alignItems="start" width="100%">
                        <div className="GetStarted__instruction-panel__step">
                            {/*{this.getDownloadButton()}*/}
                        </div>
                    </Flex>
                </div>
            )
        } else {
            return (
                <Provider theme={defaultTheme} colorScheme={"dark"}>
                    <div className="GetStarted__instruction-panel e">
                        <Flex direction="row" alignItems="end" width="100%">
                            <div className="GetStarted__instruction-panel__step">
                                <Heading level={this.getLevel()}>{this.props.content ? this.props.content.instructionsTitle3 : ""}</Heading>
                            </div>
                        </Flex>
                    </div>
                    <div className="GetStarted__instruction-panel f">
                        <Flex direction="column" alignItems="start" width="100%">
                            <div className="GetStarted__instruction-panel__step">
                                <Text>{this.props.content ? this.props.content.instructionsBody3 : ""}</Text>
                            </div>
                            {/*{this.getDownloadButton()}*/}
                        </Flex>
                    </div>
                </Provider>
            )
        }
    }

    handleDownload() {
        navigate(`${process.env.GATSBY_CRAFT_URL}/download`);
        AnalyticsRepository.trackDownloadClicked();
    }

    getLevel() {
        const isBrowser = typeof window !== "undefined";
        if(!isBrowser) {
            return 4;
        }

        if(window.innerWidth > 1124) {
            return 1;
        } else if(window.innerWidth > 768) {
            return 2;
        } else {
            return 4;
        }
    }

    handleResize() {
        if(this.props.isVisible && this.instructions.current && this.instructions.current.scrollHeight !== this.state.infoStyle.height) {
            this.setState({
                infoStyle: {
                    height: this.instructions.current.scrollHeight
                },
            });
        }
    }

    componentDidMount() {
        if(this.instructions.current) {
            window.addEventListener("resize", this.handleResize);
        }

        if(this.main.current) {
            this.main.current.addEventListener('transitionend', this.afterOpenClose);
        }
    }



    setDrawerSize() {
        if(this.instructions.current) {
            this.setState({
                infoStyle: {
                    height: this.props.isVisible ?  this.instructions.current.scrollHeight : 0,
                    opacity: this.props.isVisible ? 1 : 0,
                }
            });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.isVisible !== prevProps.isVisible) {
            this.setDrawerSize();
        }
    }

    render() {
        return (
            <Provider theme={defaultTheme} colorScheme={"dark"}>
                <Flex direction="column">
                    <div className={`GetStarted`} ref={this.main} style={this.state.infoStyle}>
                        <div className="GetStarted__grid" ref={this.instructions}>
                            <Provider theme={defaultTheme} colorScheme={"dark"}>
                                <div className="GetStarted__instruction-panel a">
                                    <Flex direction="row" alignItems="end" width="100%">
                                        <div className="GetStarted__instruction-panel__step">
                                            <Heading level={this.getLevel()}>{this.props.content ? this.props.content.instructionsTitle1 : ""}</Heading>
                                        </div>
                                    </Flex>
                                </div>
                            </Provider>
                            <Provider theme={defaultTheme} colorScheme={"dark"}>
                                <div className="GetStarted__instruction-panel b">
                                    <Flex direction="row" alignItems="end" width="100%">
                                        <div className="GetStarted__instruction-panel__step">
                                            <Text>{this.props.content ? this.props.content.instructionsBody1 : ""}</Text>
                                        </div>
                                    </Flex>
                                </div>
                            </Provider>
                            <Provider theme={defaultTheme} colorScheme={"dark"}>
                                <div className="GetStarted__instruction-panel c">
                                    <Flex direction="row" alignItems="end" width="100%">
                                        <div className="GetStarted__instruction-panel__step">
                                            <Heading level={this.getLevel()}>{this.props.content ? this.props.content.instructionsTitle2 : ""}</Heading>
                                        </div>
                                    </Flex>
                                </div>
                            </Provider>
                            <Provider theme={defaultTheme} colorScheme={"dark"}>
                                <div className="GetStarted__instruction-panel d">
                                    <Flex direction="row" alignItems="end" width="100%">
                                        <div className="GetStarted__instruction-panel__step">
                                            <Text>{this.props.content ? this.props.content.instructionsBody2 : ""}</Text>
                                        </div>
                                    </Flex>
                                </div>
                            </Provider>
                            {this.getFinalPanel()}
                        </div>
                    </div>
                </Flex>
            </Provider>
        );
    }

    componentWillUnmount() {
        if(this.main.current) {
            this.main.current.removeEventListener('transitionend', this.afterOpenClose);
        }
        if(this.instructions.current) {
            window.removeEventListener('resize', this.handleResize);
        }
    }
}