import base64url from 'base64url';
import url from 'url';

export default class Thumbnail {

    constructor(json, bucket, host) {
        this.json = json;
        this.bucket = bucket;
        this.host = host;
    }

    get id() {
        return this.json.slug;
    }

    get imageUrl() {
        return `${this.host}${this.fileName}`;
    }

    get fileName() {
        return this.json.tileFilename;
    }

    get imageUrlSmall() {
        if(this.bucket && this.host) {
            const imageRequest = JSON.stringify({
                bucket: this.bucket,
                key: this.fileName,
                edits: {
                    resize: {
                        width: Number(process.env.GATSBY_SMALL_IMAGE_RESOLUTION),
                        height: Number(process.env.GATSBY_SMALL_IMAGE_RESOLUTION),
                        fit: 'cover'
                    },
                    jpeg: {
                        quality: Number(process.env.GATSBY_SMALL_IMAGE_QUALITY)
                    }
                }
            });
            return url.resolve(this.host, btoa(imageRequest));
        }
        return `${this.imageUrl}`;
    }

    get imageUrlLarge() {
        if(this.bucket && this.host) {
            const imageRequest = JSON.stringify({
                bucket: this.bucket,
                key: this.fileName,
                edits: {
                    resize: {
                        width: Number(process.env.GATSBY_LARGE_IMAGE_RESOLUTION),
                        height: Number(process.env.GATSBY_LARGE_IMAGE_RESOLUTION),
                        fit: 'cover'
                    },
                    jpeg: {
                        quality: Number(process.env.GATSBY_LARGE_IMAGE_QUALITY)
                    }
                }
            });
            return url.resolve(this.host, btoa(imageRequest));
        }
        return `${this.imageUrl}`;
    }

    get artistName() {
        return this.json.artistName;
    }

    get artistCountry() {
        return this.json.artistCountry;
    }
}