import axios from 'axios';
import Content from "./models/Content";
import contentFallback from '../responses/fallback_content.json';

let CONTENT_CACHE = null;
let IS_DISCONNECTED = false;

export default class ContentRepository {

    /**
     * Get all the site content from remote
     * @returns {Promise<Content>}
     */
    static fetchSiteContent() {
        let url = `${process.env.GATSBY_CRAFT_URL}/getsitecontent`;
        if(CONTENT_CACHE) {
            return new Promise((fulfill,_) => {
                fulfill(CONTENT_CACHE);
            });
        } else {
            return axios.get(url)
                .then(response => {
                    if(response.status === 200) {
                        return response.data;
                    } else {
                        return new Promise((_, reject) => {
                            reject('Unable to get site content');
                        });
                    }
                })
                .catch(e => {
                    console.error(`Warning: ${e}, using content fallback`);
                    IS_DISCONNECTED = true;
                    return contentFallback;
                })
                .then(data => {
                    const content = new Content(data);
                    CONTENT_CACHE = content;
                    return content;
                });
        }

    }

    static fetchGridDimensions() {
        let url = `${process.env.GATSBY_CRAFT_URL}/getgriddimensions`;
        return axios.get(url)
            .then(response => {
                if(response.status === 200 && response.data.data.length > 0) {
                    return new Promise((fulfill,_) => {
                        fulfill(response.data.data[0]);
                    });
                } else {
                    return new Promise((_, reject) => {
                        reject('Unable to get grid dimensions');
                    });
                }
            }).catch(e => {
                console.error(`Warning: ${e}, using content fallback`);
                return new Promise((_, reject) => {
                    reject(`Unable to get grid dimensions: ${e}`);
                });
            });
    }

    static isDisconnected() {
        return IS_DISCONNECTED;
    }
}