import React from "react";
import RandomImage from "../utils/RandomImage";
import Tile from "./Tile";
import TileRepository from "../repositories/TileRepository";
import AnimationFrame from "../utils/AnimationFrame";

export default class Grid22 extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            currentEntries: null,
            page: 1,
            highlightEntries: null,
            updated: false,
            lastFrame: 0,
            startTime: null,
            frameRate: 1,
            columnWidth: this.isBrowser() ? `${window.innerWidth / props.columns - 1.5 * props.columns}px` : '1fr',
            minTileHeight: 0
        }

        this.container = React.createRef();
        this.children = [];
        for(let i = 0; i < props.rows; i++) {
            this.children.push([]);
        }

        this.handleTileClicked = this.handleTileClicked.bind(this);
        this.isBrowser = this.isBrowser.bind(this);
        this.loadNextTilePage = this.loadNextTilePage.bind(this);
        this.update = this.update.bind(this);

        this.animation = new AnimationFrame(this.state.frameRate, this.update);
    }

    isBrowser() {
        return typeof window !== "undefined"
    }

    update() {
        for(let i = 0; i < this.children.length; i++) {
            for(let j = 0; j < this.children[i].length; j++) {
                if(this.children[i][j]) {
                    this.children[i][j].update();
                }
            }
        }
    }

    loadNextTilePage() {
        const _this = this;
        TileRepository.getTilesForGrid(this.state.page)
            .then(collection => {
                let page = _this.state.page + 1;
                _this.setState({
                    currentEntries: collection,
                    page: page
                });
            })
            .catch(e => {
                console.error(e);
            });
    }

    handleTileClicked(entry, coordinate) {
        if(this.props.onTileClicked) {
            this.props.onTileClicked(entry, coordinate, this.props.language);
        }
    }

    getRows(x) {
        const _this = this;

        const rows = [];
        for(let i = 0; i < this.props.rows; i++) {
            rows.push(i);
        }

        return rows.map( y => {
            let entries = [];
            let shouldLaunch = false;
            let maxFrames = 0;
            const coordinate = {
                row: y + 1,
                col: x + 1,
            };

            if(_this.state.highlightEntries && _this.state.highlightEntries.row === coordinate.row && _this.state.highlightEntries.column === coordinate.col) {
                entries = _this.state.highlightEntries.getThumbnailsForHighlight();
                maxFrames = _this.state.highlightEntries.maxFrames;
                shouldLaunch = true;
                _this.setState({
                    highlightEntries: null,
                });
            } else if(_this.state.currentEntries) {
                entries = _this.state.currentEntries.getThumbnails(coordinate.row, coordinate.col);
                maxFrames = _this.state.currentEntries.maxFrames;
            }

            return <Tile coordinate={coordinate}
                         key={`${coordinate.row}-${coordinate.col}`}
                         entries={entries}
                         maxIndex={maxFrames - 1}
                         columnWidth={this.state.columnWidth}
                         onMaxIndex={_this.loadNextTilePage}
                         ref={(ref) => {_this.children[coordinate.row - 1][coordinate.col - 1] = ref;}}
                         onTileClicked={_this.handleTileClicked}
                         minTileHeight={_this.state.minTileHeight}
                         shouldLaunch={shouldLaunch}/>
        });
    }

    getColumns() {
        const columns = [];
        const middle = parseInt(this.props.columns / 2);

        for(let i = 0; i < this.props.columns; i++) {
            columns.push(i);
        }

        return columns.map((column, i) => {
            const spacersTop = [];
            const spacersBottom = [];

            const spacerAmount = Math.abs(i-middle);
            const toAdd = [];
            for(let j = 0; j < spacerAmount; j++) {
                toAdd.push(<div className="spacer" key={`spacer-${i}-${j}`}/>);
            }
            spacersTop.push(toAdd);
            spacersBottom.push(toAdd);

            return (
                <div key={`column-${column}`} style={{
                    gridArea: `1 / ${column + 1} / 2 / ${column + 2}`,
                }}>
                    {spacersTop.map((spacers) => {
                        return spacers.map((spacer) => {
                            return spacer;
                        })
                    })}
                    {this.getRows(column)}
                    {spacersBottom.map((spacers) => {
                        return spacers.map((spacer) => {
                            return spacer;
                        })
                    })}
                </div>
            )
        });
    }

    componentDidMount() {
        if(this.isBrowser()) {
            window.addEventListener('resize', () => {
                this.setState({
                    columnWidth: `${window.innerWidth / this.props.columns - 1.5 * this.props.columns}px`,
                    minTileHeight: this.isBrowser() ? window.innerHeight / this.props.rows - 1.5 * this.props.rows : 0
                });
            });
        }
        this.loadNextTilePage();
        this.animation.start();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.highlightTile && this.props.highlightTile !== prevProps.highlightTile) {
            this.setState({
                highlightEntries: this.props.highlightTile
            })
        }
    }

    componentWillUnmount() {
        this.animation.stop();
    }

    render() {
        const columns = this.getColumns();

        return(
            <div className="Grid22">
                <div className="inner" style={{
                    gridTemplateColumns: `repeat(${this.props.columns}, minmax(200px, ${this.state.columnWidth}))`,
                }}>
                    {columns}
                </div>
            </div>
        )
    }
}