export default class DeviceRepository {

    static isMobile() {
        if (typeof window === 'undefined') {
            return false;
        }
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }

    static isIE() {
        if(typeof window == 'undefined') return false;

        return !!window.MSInputMethodContext && !!document.documentMode;
    }

    static applyVarPolyfill() {
        let script = document.createElement('script');
        script.setAttribute('src', 'https://cdn.jsdelivr.net/gh/nuxodin/ie11CustomProperties@4.1.0/ie11CustomProperties.min.js');
        document.body.appendChild(script);
    }

    static getWidth() {
        if (typeof window === 'undefined') {
            return 0;
        }
        return window.innerWidth;
    }

    static getHeight() {
        if (typeof window === 'undefined') {
            return 0;
        }
        return Math.max(window.document.body.scrollHeight, window.document.body.scrollTop);
    }

    static postHeightToParent(override = null) {
        if (typeof window === 'undefined') {
            return;
        }
        window.parent.postMessage(override ? override : this.getHeight(), '*');
    }

    static postScrollToTop() {
        if (typeof window === 'undefined') {
            return;
        }
        window.parent.postMessage('scroll', '*');
    }

    static isLarge() {
        return this.getWidth() > 880;
    }

    static isPortrait() {
        if (typeof window === 'undefined') {
            return false;
        }
        return window.matchMedia("(orientation: portrait)").matches;
    }

    static isExpired() {
        const expiration = Number(process.env.GATSBY_EXPIRATION_TIMESTAMP);
        const now = Math.floor(Date.now() / 1000);
        return now >= expiration;
    }
}