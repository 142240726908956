import React from "react";
import '../scss/main.scss'
import TileRepository from "../repositories/TileRepository";
import Modal from "./Modal";
import Grid22 from "./Grid22";
import ContentRepository from "../repositories/ContentRepository";

export default class Mural extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            currentPage: 1,
            currentCoordinate: null,
            currentEntry: null,
            isLightboxVisible: false,
            lightboxImages: [],
            errorState: null,
        }

        this.handleTileClicked = this.handleTileClicked.bind(this);
        this.handleModalClosed = this.handleModalClosed.bind(this);
    }

    componentDidMount() {
        this.setState({
            errorState: this.props.errorState,
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.errorState && this.props.errorState !== prevProps.errorState) {
            this.setState({
                errorState: this.props.errorState,
                isLightboxVisible: true,
            })
        }
        if(this.props.highlightTile !== prevProps.highlightTile) {
            let collection = this.props.highlightTile;
            this.handleTileClicked(collection.getThumbnailsForHighlight()[0], {row: collection.row, col: collection.column}, collection.languageId)
        }
    }

    handleTileClicked(entry, coordinate, language, page = 1) {
        if(!entry) return;

        const langId = typeof language === 'object' ? language.id : language;
        const _this = this;
        TileRepository.getTile(entry.id, langId, page)
            .then(collection => {
                _this.setState({
                    isLightboxVisible: true,
                    lightboxImages: collection.getThumbnailsForHighlight(),
                    currentEntry: collection.getThumbnailsForHighlight()[0] ?? null,
                    currentPage: page,
                    currentLanguage: language,
                    currentCoordinate: coordinate
                });
            })
            .catch(e => {
                console.log(e);
            });
    }

    handleCloseLightbox() {
        this.setState({
            isLightboxVisible : false
        })
    }

    handleModalClosed() {
        this.setState({
            isLightboxVisible: false,
            errorState: null,
        });
    }

    render() {
        return (
            <div className="Mural">
                <Grid22 rows={Number(process.env.GATSBY_GRID_ROWS)} columns={Number(process.env.GATSBY_GRID_COLUMNS)} />
                <Modal
                    content={this.props.content}
                    isVisible={this.state.isLightboxVisible}
                    errorState={this.state.errorState}
                    onPageEnd={() => {this.handleTileClicked(this.state.currentEntry, this.state.currentCoordinate, this.state.currentLanguage, this.state.currentPage + 1)}}
                    entries={this.state.lightboxImages} onClose={this.handleModalClosed}
                />
            </div>
        )
    }
}