import Thumbnail from "./Thumbnail";

export default class GridTileCollection {

    constructor(json) {
        this.json = json;
    }

    get maxFrames() {
        return this.json.maxOnPage;
    }

    get total() {
        return this.json.limitPerPage;
    }

    get language() {
        return this.json.language;
    }

    get languageId() {
        return this.json.languageId ? this.json.languageId : this.json.languageid;
    }

    get timestamp() {
        return this.json.timestamp;
    }

    get currentPage() {
        return this.json.current_page;
    }

    get row() {
        return this.json.gridRow;
    }

    get column() {
        return this.json.gridCol ? this.json.gridCol : this.json.grodCol;
    }

    getThumbnailsForHighlight() {
        let raw = this.json.data;
        let thumbs = [];
        const bucket = this.s3Bucket;
        const host = this.assetHost;
        raw.forEach(v => {
            thumbs.push(new Thumbnail(v, bucket, host));
        });
        return thumbs;
    }

    getThumbnails(row, col) {
        if(!this.json.data) return [];

        let raw = this.json.data[`${row}-${col}`];
        let thumbs = [];
        const bucket = this.s3Bucket;
        const host = this.assetHost;
        raw.forEach(v => {
            thumbs.push(new Thumbnail(v, bucket, host));
        });
        return thumbs;
    }

    getThumbnailForStep(row, col, step) {
        let thumbs = this.getThumbnails(row, col);
        if(thumbs.length > 0) {
            let i = step % thumbs.length;
            return thumbs[i];
        } else {
            return null;
        }
    }

    get s3Bucket() {
        return this.json.s3Bucket;
    }

    get assetHost() {
        return this.json.assetHost;
    }

}