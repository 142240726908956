import React from "react";
import "../scss/main.scss";

export default class Tile extends React.Component {

    static get MIN_DURATION_SECONDS() {
        return 7;
    }

    static get MAX_DURATION_SECONDS() {
        return 60;
    }

    static get FRAMERATE() {
        return 1;
    }

    constructor(props) {
        super(props);

        this.state = {
            index: 0,
            currentEntry: null,
            duration: Tile.FRAMERATE * Math.floor(Math.random() * (Tile.MAX_DURATION_SECONDS - Tile.MIN_DURATION_SECONDS) + Tile.MIN_DURATION_SECONDS),
            entries: this.props.entries,
            lastEntry: null,
            isPlaying: true,
            frame: 0
        }

        this.displayEntry = null;

        this.container = React.createRef();
        this.foreground = React.createRef();

        this.handleTileTouched = this.handleTileTouched.bind(this);
        this.setImage = this.setImage.bind(this);
        this.update = this.update.bind(this);
        this.play = this.play.bind(this);
        this.pause = this.pause.bind(this);
    }

    triggerHighlight(collection) {
        this.setState({
            index: 0,
            currentEntry: collection[0],
            entries: collection,
        }, this.play);
    }

    getDisplayHeight(naturalWidth, naturalHeight) {
        const containerWidth = this.foreground.current.offsetWidth;
        const ratio = naturalWidth / naturalHeight;
        const width = containerWidth;
        const height = containerWidth / ratio;
        return height;
    }

    setImage(nextIndex) {
        const _this = this;

        return new Promise((resolve, reject) => {

            function afterFadeout() {
                _this.foreground.current.removeEventListener('transitionend', afterFadeout);

                let preloader = document.createElement('img');
                preloader.addEventListener('load', (e) => {
                    const height = _this.getDisplayHeight(e.target.naturalWidth, e.target.naturalHeight);
                    _this.foreground.current.src = e.target.src;
                    _this.foreground.current.setAttribute("id", _this.state.currentEntry.id);
                    _this.displayEntry = _this.state.currentEntry;
                    _this.foreground.current.addEventListener('transitionend', afterFadein);
                    _this.foreground.current.style.opacity = 1;
                    _this.foreground.current.style.height = `${height}px`;
                    _this.container.current.style.opacity = 0;
                    _this.container.current.style.height = `${height}px`;
                    preloader.remove();
                });
                preloader.src = _this.state.currentEntry.imageUrlSmall;
            }

            function afterFadein() {
                _this.foreground.current.removeEventListener('transitionend', afterFadein);

                _this.setState({
                    index: nextIndex,
                    lastEntry: _this.state.currentEntry,
                    currentEntry: _this.state.entries[nextIndex],
                }, resolve);
            }

            if(this.container.current && this.foreground.current) {
                _this.container.current.src = _this.foreground.current.src;
                const height = _this.getDisplayHeight(_this.foreground.current.naturalWidth, _this.foreground.current.naturalHeight);
                _this.foreground.current.addEventListener('transitionend', afterFadeout);
                _this.foreground.current.style.opacity = 0;
                _this.container.current.style.opacity = 1;
            } else {
                reject();
            }
        });
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if(nextProps.entries !== this.props.entries) {
            return true;
        }
        return false;
    }

    async update() {
        const _this = this;
        const nextFrame = (_this.state.frame + 1) % _this.state.duration;
        const currentFrame = this.state.frame;
        const nextIndex = (_this.state.index + 1) % _this.state.entries.length;

        if(this.state.isPlaying && this.state.currentEntry) {

            _this.setState({
                frame: nextFrame
            });

            if(currentFrame === 0) {

                await _this.setImage(nextIndex);

                if(_this.props.maxIndex > 0 && nextIndex === _this.props.maxIndex && _this.props.onMaxIndex) {
                    _this.props.onMaxIndex();
                }
            }
        }
    }

    play() {
        if(this.state.currentEntry) {
            const _this = this;
            this.setState({
                isPlaying: true,
            }, () => {
                _this.update();
            });
        }
    }

    pause() {
        if(this.state.currentEntry) {
            this.setState({
                isPlaying: false,
            });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.entries !== this.props.entries && this.props.entries.length > 0) {
            const _this = this;
            let entries = this.state.entries.slice().concat(this.props.entries);

            let idx = this.state.index;
            this.setState({
                currentEntry: entries[idx],
                entries: entries,
            }, () => {
                _this.play();
                if(this.props.shouldLaunch) {
                    _this.handleTileTouched();
                }
            });
        }
    }

    handleTileTouched(e) {
        if(this.props.onTileClicked && this.state.currentEntry) {
            this.props.onTileClicked(this.displayEntry, this.props.coordinate);
        }
    }

    render() {
        return (
            <div className="Tile" style={{minHeight: `${this.props.minTileHeight}px`}} onClick={this.handleTileTouched} onMouseEnter={this.pause} onMouseLeave={this.play}>
                <img className="Tile__background" ref={this.container}/>
                <img className="Tile__foreground" ref={this.foreground}/>
            </div>
        )
    }
}