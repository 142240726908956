import GridTileCollection from "./models/GridTileCollection";
import Language from "./models/Language";
import Thumbnail from "./models/Thumbnail";
import languageFallback from '../responses/fallback_languages.json';
import axios from 'axios';

let LANGUAGES_CACHE = null;
let GRIDTILES_TIMESTAMP = null;
let DETAILS_TIMESTAMP = null;
let CAROUSEL_TIMESTAMP = null;
let languageMap = null;

export default class TileRepository {

    /**
     * Returns a promise for the details of one specific tile
     * @param id
     * @param page
     * @param timestamp
     * @returns {Promise<Thumbnail[]>}
     */
    static getTile(id, page = 1, timestamp = DETAILS_TIMESTAMP) {
        let url = `${process.env.GATSBY_CRAFT_URL}/gridtiles/tileid:${id}`;

        if(timestamp) {
            url += `/ts:${timestamp}/pg:${page-1}`;
        }

        return axios.get(url)
            .then(response => {
                if(response.status === 200 && response.data && !response.data.error) {
                    return response.data;
                } else {
                    return new Promise((_, reject) => {
                        reject(response.data && response.data.error ? response.data.error : 'Tile not found');
                    });
                }
            })
            .then(data => {
                return new Promise((resolve, reject) => {
                    if(data.error) {
                        reject(data.error);
                    } else {
                        DETAILS_TIMESTAMP = data.timestamp;
                        resolve(new GridTileCollection(data));
                    }
                });
            });
    }

    /**
     * Returns a promise with collection of thumbnail images for a specific grid
     * @param page
     * @param timestamp
     * @returns {Promise<GridTileCollection>}
     */
    static getTilesForGrid(page = 1, timestamp = GRIDTILES_TIMESTAMP) {
        let suffix = '';
        if(timestamp) {
            suffix = page > 1 ? `ts:${timestamp}/pg:${page - 1}` : `ts:${timestamp}`;
        }

        return axios.get(`${process.env.GATSBY_CRAFT_URL}/gridtiles/${suffix}`)
            .then(response => {
                if(response.status === 200 && response.data && !response.data.error) {
                    return response.data;
                } else {
                    return new Promise((fulfill, reject) => {
                        if(response.status === 404 || response.data.error) {
                            fulfill(null);
                        } else {
                            reject('Tiles for language not found');
                        }
                    });
                }
            })
            .catch(e => {
                if(e.response && e.response.status === 404) {
                    return null;
                } else {
                    // console.error(`Warning: ${e}, using fallback tiles`);
                    // const path = `./fallback_gridtiles_${languageId}.json`;
                    // return axios.get(path)
                    //     .then(local => {
                    //         return local.data;
                    //     });
                    //todo: use fallback tiles
                }
            })
            .then(data => {
                if(data) {
                    return new Promise((resolve, _) => {
                        let collection = new GridTileCollection(data);
                        GRIDTILES_TIMESTAMP = collection.timestamp;
                        resolve(new GridTileCollection(data));
                    });
                } else {
                    return new Promise((_, reject) => {
                        reject('No tiles to return');
                    });
                }
            });
    }

    /**
     * Returns a promise for an array of Entry objects d
     * corresponding to a specific coordinate of a grid
     * @param row
     * @param col
     * @param page
     * @param timestamp
     * @returns {Promise<Thumbnail[]>}
     */
    static getCarouselForCoordinate(row, col, page = 1, timestamp = CAROUSEL_TIMESTAMP) {
        let url = timestamp ?
            `${process.env.GATSBY_CRAFT_URL}/gridtiles/row:${row},col:${col}/ts:${timestamp}/pg:${page-1}` :
            `${process.env.GATSBY_CRAFT_URL}/gridtiles/row:${row},col:${col}`;

        return axios.get(url)
            .then(response => {
                if(response.status === 200 && response.data && !response.data.error) {
                    return response.data;
                } else {
                    return new Promise((_, reject) => {
                        reject('Tiles not found');
                    });
                }
            })
            .then(data => {
                return new Promise((resolve, _) => {
                    CAROUSEL_TIMESTAMP = data.timestamp;
                    let e = data.data[`${row}-${col}`];
                    const host = data.assetHost;
                    const bucket = data.s3Bucket;
                    let entries = [];
                    e.forEach(v => {
                        entries.push(new Thumbnail(v, bucket, host));
                    });
                    resolve(entries);
                });
            });
    }

    /**
     * Get a list of all the languages in the system
     * @returns {Promise<Language[]>}
     */
    static getLanguages() {
        if(LANGUAGES_CACHE) {
            return new Promise((fulfill, _) => {
                fulfill(LANGUAGES_CACHE);
            });
        } else {
            return axios.get(`${process.env.GATSBY_CRAFT_URL}/getlanguages`)
                .then(response => {
                    if(response.status === 200 && response.data && !response.data.error) {
                        return response.data;
                    } else {
                        return new Promise((_, reject) => {

                            reject("Couldn't get languages");
                        });
                    }
                })
                .catch(e => {
                    console.error(`Warning: ${e}, using language fallback`);
                    return languageFallback;
                })
                .then(data => {
                    return new Promise((resolve, _) => {
                        let e = data.data;
                        let languages = [];
                        languageMap = new Map();
                        e.forEach(v => {
                            let lang = new Language(v);
                            languageMap.set(lang.name.toLowerCase(), lang.id);
                            languages.push(lang);
                        });
                        LANGUAGES_CACHE = languages;
                        resolve(languages);
                    });
                });
        }
    }

    /**
     * Submit and image
     * @param firstName
     * @param lastName
     * @param country
     * @param file
     * @param onUploadProgress
     * @returns {Promise<string>}
     */
    static submitImage(firstName, lastName, country, file, onUploadProgress) {
        let formData = new FormData();
        formData.append("fields[artistName]", `${firstName} ${lastName}`);
        formData.append("fields[artistCountry]", country);
        formData.append("fields[gridTileAsset]", file);
        formData.append("title", String(Math.floor(Date.now() / 1000)))
        formData.append("sectionUid", process.env.GATSBY_SECTION_UID);
        formData.append("action", "guest-entries/save");

        const options = {
            method: 'post',
            url: process.env.GATSBY_CRAFT_URL,
            data: formData,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: onUploadProgress
        }

        return axios.post(process.env.GATSBY_CRAFT_URL, formData, options)
            .then(response => {
                if(response.status === 200) {
                    return response.data;
                } else {
                    return new Promise((_,reject) => {reject('Error uploading file')});
                }
            })
            .then(data => {
                return new Promise((resolve, reject) => {
                    if(data.success) {
                        resolve(data.title);
                    } else {
                        reject("Unable to save entry");
                    }
                });
            });

    }

    static getMockLanguages() {
        return new Promise((resolve, _) => {
            resolve([
                new Language({
                    categoryId: 3,
                    title: "English"
                }),
                new Language({
                    categoryId: 4,
                    title: "Spanish"
                })
            ]);
        });
    }
}