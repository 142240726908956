import React from "react";
import "../scss/main.scss"
import Mural from "./Mural";
import Toolbar from "./Toolbar";
import withLocation from "./withLocation";
import ContentRepository from "../repositories/ContentRepository";
import DeviceRepository from "../repositories/DeviceRepository";
import AnalyticsRepository from "../repositories/AnalyticsRepository";

class App extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showInstructions: false,
            content: null,
            isDisabled: this.props.isDisabled ?? false,
        };

        this.handleGetStartedClicked = this.handleGetStartedClicked.bind(this);
        this.handleCloseGetStarted = this.handleCloseGetStarted.bind(this);
    }

    componentDidMount() {
        const _this = this;
        ContentRepository.fetchSiteContent()
            .then(content => {
                _this.setState({
                    content: content
                });
            })
            .catch(e => {
                _this.setState({
                    isDisabled: true,
                });
            });
    }

    static shuffle(arr) {
        for(let i = arr.length - 1; i > 0; i--){
            const j = Math.floor(Math.random() * i)
            const temp = arr[i]
            arr[i] = arr[j]
            arr[j] = temp
        }
    }

    handleGetStartedClicked() {
        this.setState({
            showInstructions: true,
        }, () => {
            AnalyticsRepository.trackPageView('GetStarted');
        });
    }

    handleCloseGetStarted() {
        this.setState({
            showInstructions: false,
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.state.content !== prevState.content || this.state.languages !== prevState.languages) {
            DeviceRepository.postHeightToParent();
        }
    }

    render() {
        if(this.props.languages && this.state.content || true) {
            return (
                <div className="App">
                    <Toolbar isDisabled={this.state.isDisabled} content={this.state.content} onGetStartedClicked={this.handleGetStartedClicked} onCloseGetStartedClicked={this.handleCloseGetStarted} showInstructions={this.state.showInstructions}/>
                    <Mural content={this.state.content} errorState={this.props.errorState} highlightTile={this.props.highlightTile} languages={this.props.languages} showInstructions={this.state.showInstructions}/>
                </div>
            );
        } else {
            return (<div/>);
        }
    }
}

export default withLocation(App);