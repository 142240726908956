import React from "react";
import "../scss/main.scss"
import {Text, Heading, defaultTheme, Provider} from '@adobe/react-spectrum';

export default class Image extends React.Component {

    constructor(props) {
        super(props);

        this.ref = React.createRef();
    }

    isCenter() {
        if(this.ref.current) {
            return this.ref.current.parentElement.parentElement.classList.contains('slick-center');
        }
        return false;
    }

    render() {
        let shouldShowInfo = this.props.displayName || this.props.country;
        if(this.props.image) {
            return (
                <div className="Image" ref={this.ref}>
                    <div className="Image__container">
                        <div id={this.props.imageId ?? ''} className="inner"
                             style={{
                                 backgroundImage: `url('${this.props.image ?? ''}')`
                             }}>
                        </div>
                    </div>
                    <div className="Image__info" style={{display: shouldShowInfo ? 'block' : 'none'}}>
                        <Heading level={3}>{this.props.displayName}</Heading>
                        <Text>{this.props.country}</Text>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="Image" ref={this.ref}>
                    <div className="Image__container">
                        <div className="inner">
                            <Provider theme={defaultTheme} colorScheme="light">
                                <Heading level={2}>{this.props.heading}</Heading>
                                <Text>{this.props.message}</Text>
                            </Provider>
                        </div>
                    </div>
                    <div className="Image__info" style={{display: shouldShowInfo ? 'block' : 'none'}}>

                    </div>
                </div>
            )
        }
    }
}