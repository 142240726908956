import bg_english from "../../images/AllTogetherNow_English.svg";
import bg_french from "../../images/AllTogetherNow_French.svg";
import bg_german from "../../images/AllTogetherNow_German.svg";
import bg_japanese from "../../images/AllTogetherNow_Japanese.svg";
import bg_korean from "../../images/AllTogetherNow_Korean.svg";
import bg_spanish from "../../images/AllTogetherNow_Spanish.svg";

export default class Language {

    constructor(json) {
        this.json = json;

        this.backgrounds = new Map();
        this.backgrounds.set("english", bg_english);
        this.backgrounds.set("french", bg_french);
        this.backgrounds.set("german", bg_german);
        this.backgrounds.set("japanese", bg_japanese);
        this.backgrounds.set("korean", bg_korean);
        this.backgrounds.set("spanish", bg_spanish);
    }

    get id() {
        return this.json.categoryId;
    }

    get name() {
        return this.json.title;
    }

    getDefaultBackgroundUrl() {
        return this.backgrounds.get(this.name.toLowerCase());
    }
}