/*
{
"data": [
{
"appTitle": "Site Content",
"appSubtitle": null,
"instructionsTitle": null,
"instructionsSubtitle": null,
"instructionsBody": null,
"submitTitle": null,
"submitSubtitle": null,
"submitBody": null,
"confirmationTitle": null,
"confirmationSubtitle": null,
"confirmationBody": null
}
],
"meta": {
"pagination": {
"total": 1,
"count": 1,
"per_page": 100,
"current_page": 1,
"total_pages": 1,
"links": {}
}
}
}
 */

export default class Content {

    constructor(json) {
        this.json = json.data ? json.data[0] : json;
    }

    get appTitle() {
        return this.json.appTitle;
    }

    get appSubtitle() {
        return this.json.appSubtitle;
    }

    get instructionsTitle1() {
        return this.json.instructionsTitle1;
    }

    get instructionsSubtitle() {
        return this.json.instructionsSubtitle;
    }

    get instructionsBody1() {
        return this.json.instructionsBody1;
    }

    get instructionsTitle2() {
        return this.json.instructionsTitle2;
    }

    get instructionsBody2() {
        return this.json.instructionsBody2;
    }

    get instructionsTitle3() {
        return this.json.instructionsTitle3;
    }

    get instructionsBody3() {
        return this.json.instructionsBody3;
    }

    get expirationTitle() {
        return this.json.expirationTitle;
    }

    get expirationBody() {
        return this.json.expirationBody;
    }

    get submitTitle() {
        return this.json.submitTitle;
    }

    get submitSubtitle() {
        return this.json.submitSubtitle;
    }

    get submitBody() {
        return this.json.submitBody;
    }

    get confirmationTitle() {
        return this.json.confirmationTitle;
    }

    get confirmationSubtitle() {
        return this.json.confirmationSubtitle;
    }

    get confirmationBody() {
        return this.json.confirmationBody;
    }

    get pendingTitle() {
        return this.json.pendingTitle;
    }

    get pendingMessage() {
        return this.json.pendingMessage;
    }

    get rejectedTitle() {
        return this.json.rejectedTitle;
    }

    get rejectedMessage() {
        return this.json.rejectedMessage;
    }

    get notFoundTitle() {
        return this.json.notFoundTitle;
    }

    get notFoundMessage() {
        return this.json.notFoundMessage;
    }

    get frontdoorMuralTitle() {
        return this.json.frontdoorMuralTitle;
    }

    get frontdoorMuralDescription() {
        return this.json.frontdoorMuralDescription;
    }

    get frontdoorRedditTitle() {
        return this.json.frontdoorRedditTitle;
    }

    get frontdoorRedditDescription() {
        return this.json.frontdoorRedditDescription;
    }
}