import {Helmet} from "react-helmet";
import React from "react";

export default class AnalyticsRepository {
    static getFontBootstrap() {
        return `(function(d) {
                    var config = {
                    kitId: 'avs8rfx',
                    scriptTimeout: 3000,
                    async: true
                },
                    h=d.documentElement,t=setTimeout(function(){h.className=h.className.replace(/\bwf-loading\b/g,"")+" wf-inactive";},config.scriptTimeout),tk=d.createElement("script"),f=false,s=d.getElementsByTagName("script")[0],a;h.className+=" wf-loading";tk.src='https://use.typekit.net/'+config.kitId+'.js';tk.async=true;tk.onload=tk.onreadystatechange=function(){a=this.readyState;if(f||a&&a!="complete"&&a!="loaded")return;f=true;clearTimeout(t);try{Typekit.load(config)}catch(e){}};s.parentNode.insertBefore(tk,s)
                })(document);`;
    }

    static getAnalyticsBootstrap(env = process.env.NODE_ENV) {
        if (env === 'development') {
            return `
            window.marketingtech = {
                adobe: {
                    launch: {
                        property: 'global',
                        environment: 'dev' // “production” for prod/live site or “stage” for qa/staging site
                },
                analytics: {
                    additionalAccounts: '' //if there are any additional report suites send values with “,” separated Ex: “RS1,RS2”
                },
                target: false, // if target needs to be enabled else false
                    audienceManager: true // if audience manager needs to be enabled else false
                }
            };`;
        } else {
            return `
            window.marketingtech = {
                adobe: {
                    launch: {
                        property: 'global',
                        environment: 'production' // “production” for prod/live site or “stage” for qa/staging site
                },
                analytics: {
                    additionalAccounts: 'adbmaxprod' //if there are any additional report suites send values with “,” separated Ex: “RS1,RS2”
                },
                target: false, // if target needs to be enabled else false
                    audienceManager: true // if audience manager needs to be enabled else false
                }
            };`;
        }
    }

    static getAllBootstrapScripts() {
        return (
            <Helmet>
                <script type="text/javascript">
                    {AnalyticsRepository.getAnalyticsBootstrap()}
                </script>
                <script src="https://www.adobe.com/marketingtech/main.min.js"></script>
                <script>
                    {AnalyticsRepository.getFontBootstrap()}
                </script>
            </Helmet>
        )
    }

    static hasWindow() {
        return !(typeof window === 'undefined')
    }

    static deleteProperties() {
        if (this.hasWindow() && window.digitalData._delete) {
            window.digitalData._delete('digitalData.primaryEvent.eventInfo.eventName');
            window.digitalData._delete('digitalData.primaryEvent.eventInfo.eventAction');
            window.digitalData._delete('digitalData.primaryEvent.eventInfo.interaction.click');
        }
    }

    /**
     * Track when a user lands on a component
     * @param pageName - one of: GetStarted|SubmitYourArt|ThankYou
     */
    static trackPageView(pageName) {
        if(!this.hasWindow() || !('_satellite' in window)) return;
        const name = `adobe.com:max:2020:engage:globalartproject:${pageName}`;
        window.digitalData = {
            page: {
                pageInfo: {
                    pageName: name
                }
            },
        }
        window._satellite.track('state', {
            digitalData: window.digitalData._snapshot()
        });
    }

    static submitEvent() {
        if(!this.hasWindow() || !('_satellite' in window)) return;
        window._satellite.track('event', {
            digitalData: window.digitalData._snapshot()
        });
        this.deleteProperties();
    }

    /**
     * A frontdoor card has been clicked
     * @param cardName - one of: MAX|Reddit
     */
    static trackFrontdoorClick(cardName) {
        if(!this.hasWindow()) return;

        window.digitalData = {
            primaryEvent: {
                eventInfo: {
                    eventName:`${cardName}|GlobalArtProject|Mural`,
                    eventAction:'muralClick',
                    interaction: {
                        click:
                            `${cardName}|GlobalArtProject|Mural`
                    }
                }
            }
        }

        this.submitEvent();
        this.deleteProperties();
    }

    static trackDownloadClicked() {
        window.digitalData = {
            primaryEvent: {
                eventInfo: {
                    eventName:
                        'Download|GlobalArtProject|Mural',
                    eventAction:
                        'muralDownloadClick',
                    interaction: {
                        click:
                            'Download|GlobalArtProject|Mural'
                    }
                }
            }
        }

        this.submitEvent();
        this.deleteProperties();
    }

    static trackGetStartedClicked() {
        window.digitalData = {
            primaryEvent: {
                eventInfo: {
                    eventName:
                        'GetStarted|GlobalArtProject|Mural',
                    eventAction:
                        'muralGetStartedClick',
                    interaction: {
                        click:
                            'GetStarted|GlobalArtProject|Mural'
                    }
                }
            }
        }

        this.submitEvent();
        this.deleteProperties();
    }

    static trackSubmitClicked() {
        window.digitalData = {
            primaryEvent: {
                eventInfo: {
                    eventName:
                        'Submit|GlobalArtProject|Mural',
                    eventAction:
                        'muralSubmitClick',
                    interaction: {
                        click:
                            'Submit|GlobalArtProject|Mural'
                    }
                }
            }
        }

        this.submitEvent();
        this.deleteProperties();
    }
}