import React from "react";
import "../scss/main.scss";
import hamburgerIcon from '../images/hamburger_icon.svg';
import closeIcon from '../images/close_icon.svg';
import {Provider, defaultTheme, Button, Heading, Text, ActionButton, Flex} from '@adobe/react-spectrum';
import GetStarted from "./GetStarted";
import DeviceRepository from "../repositories/DeviceRepository";
import AnalyticsRepository from "../repositories/AnalyticsRepository";


export default class Toolbar extends React.Component {

    constructor(props) {
        super(props);

        this.handleGetStartedClicked = this.handleGetStartedClicked.bind(this);
        this.handleCloseClicked = this.handleCloseClicked.bind(this);
    }

    handleGetStartedClicked() {
        if(this.props.onGetStartedClicked) {
            this.props.onGetStartedClicked();
        }

        AnalyticsRepository.trackGetStartedClicked();
    }

    handleCloseClicked() {
        if(this.props.onCloseGetStartedClicked) {
            this.props.onCloseGetStartedClicked();
        }
    }

    getButton() {
        if(this.props.showInstructions) {
            if(DeviceRepository.isIE()) {
                return (
                    <div>
                        <div className="visible-md-up">
                            <button className="fallback__button-action" onClick={this.handleCloseClicked} style={{minWidth:'99px', minHeight:'34px'}}>
                                <img src={closeIcon}/>
                            </button>
                        </div>
                        <div className="Toolbar__button hidden-md-up">
                            <button className="fallback__button-action" onClick={this.handleCloseClicked}>
                                <img src={closeIcon}/>
                            </button>
                        </div>
                    </div>
                );
            } else {
                return (
                    <div>
                        <div className="visible-md-up">
                            <ActionButton isQuiet onPress={this.handleCloseClicked} minWidth="99px" minHeight='34px'>
                                <img src={closeIcon}/>
                            </ActionButton>
                        </div>
                        <div className="Toolbar__button hidden-md-up">
                            <ActionButton isQuiet onPress={this.handleCloseClicked}>
                                <img src={closeIcon}/>
                            </ActionButton>
                        </div>
                    </div>
                );
            }
        } else {
            if(DeviceRepository.isIE()) {
                return (
                    <div>
                        <div className="visible-md-up">
                            <button className="fallback__button-cta" onClick={this.handleGetStartedClicked}>
                                {!DeviceRepository.isExpired() ? "Get started" : "About"}
                            </button>
                        </div>
                        <div className="Toolbar__button hidden-md-up">
                            <button className="fallback__button-action" onClick={this.handleGetStartedClicked}>
                                <img src={hamburgerIcon}/>
                            </button>
                        </div>
                    </div>
                );
            } else {
                return (
                    <div>
                        <div className="visible-md-up">
                            <Button variant="cta" onPress={this.handleGetStartedClicked}>
                                {!DeviceRepository.isExpired() ? "Get started" : "About"}
                            </Button>
                        </div>
                        <div className="Toolbar__button hidden-md-up">
                            <ActionButton isQuiet onPress={this.handleGetStartedClicked}>
                                <img src={hamburgerIcon}/>
                            </ActionButton>
                        </div>
                    </div>
                );
            }
        }
    }

    getSubtitle() {
        if(this.props.showInstructions && !DeviceRepository.isMobile()) {
            return (
                <Text>{` / ${this.props.content ? this.props.content.instructionsSubtitle : ""}`}</Text>
            )
        } else {
            return (
                <span/>
            )
        }
    }

    getHeadline() {
        if(this.props.showInstructions) {
            return (
                <span/>
            )
        } else {
            return (
                <Text>{this.props.content ? this.props.content.appSubtitle : ""}</Text>
            )
        }
    }

    render() {
        return (
            <Provider theme={defaultTheme} colorScheme={"dark"}>
                <Flex direction="column">
                    <div className="Toolbar">
                        <div className="Toolbar__title--1">
                            <Heading level={4}>{this.props.content ? this.props.content.appTitle : ""}</Heading>
                            {this.getSubtitle()}
                        </div>
                        <div className="Toolbar__headline visible-md-up">
                            {this.getHeadline()}
                        </div>
                        {this.getButton()}
                    </div>
                    <GetStarted isDisabled={this.props.isDisabled ?? false} content={this.props.content} isVisible={this.props.showInstructions}/>
                    <div className="Toolbar__gradient"/>
                </Flex>

            </Provider>
        )
    }

}