import React from 'react';
import {defaultTheme, Provider} from '@adobe/react-spectrum';
import "../scss/main.scss";
import Slider from "react-slick";
import Image from "./Image";
import left from '../images/left.svg';
import right from '../images/right.svg';
import closeIcon from "../images/close_icon.svg";
import DeviceRepository from "../repositories/DeviceRepository";

export default class Modal extends React.Component {

    constructor(props) {
        super(props);

        this.bg = React.createRef();
        this.slider = React.createRef();

        this.state = {
            entries: this.props.entries,
            sliderKey: 1,
            currentIndex: 0,
        }

        this.handleCloseClicked = this.handleCloseClicked.bind(this);
        this.handleClickedBackground = this.handleClickedBackground.bind(this);
        this.handleSlideChange = this.handleSlideChange.bind(this);
        this.handleReinit = this.handleReinit.bind(this);
        this.handleKeypress = this.handleKeypress.bind(this);
    }

    toggleScrollBehavior(shouldDisable) {
        let body = document.querySelector('body');
        if(body) {
            body.style.overflowY = shouldDisable ? 'hidden' : 'scroll';
            body.scrollTop = 0;
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const _this = this;
        if(this.props.isVisible !== prevProps.isVisible) {
            this.toggleScrollBehavior(this.props.isVisible);
        }
        if(this.props.errorState !== prevProps.errorState) {
            this.forceUpdate();
        }
        if(this.props.entries !== prevProps.entries) {
            let entries = this.state.entries.slice().concat(this.props.entries);
            this.setState({
                entries: entries
            }, () => {
                _this.handleSlideChange(_this.state.currentIndex);
            });
        }
        if(this.props.isVisible === true && prevProps.isVisible === false) {
            DeviceRepository.postScrollToTop();
            setTimeout(() => {
                let active = document.querySelector('.slick-active');
                if(active) {
                    active.focus();
                }
            }, 500);
        }
    }

    getPrevArrow() {
        return (
            <img src={left}/>
        );
    }

    getNextArrow() {
        return(
            <img src={right}/>
        )
    }

    getImages() {
        if(this.props.errorState) {
            let heading = "";
            let message = "";
            switch(this.props.errorState.code) {
                case 1:
                    heading = this.props.content ? this.props.content.pendingTitle : "";
                    message = this.props.content ? this.props.content.pendingMessage : "";
                    break;
                case 2:
                    heading = this.props.content ? this.props.content.rejectedTitle : "";
                    message = this.props.content ? `${this.props.content.rejectedMessage}\n\nReason for rejection:\n${this.props.errorState.message}` : "";
                    break;
                default:
                    heading = this.props.content ? this.props.content.notFoundTitle : "";
                    message = this.props.content ? this.props.content.notFoundMessage : "";
            }
            return (
                <Image heading={heading} message={message}/>
            )
        } else {
            return this.state.entries.map(entry => {
                return (
                    <Image
                        image={entry.imageUrlLarge}
                        key={entry.id}
                        imageId={entry.id}
                        displayName={entry.artistName}
                        country={entry.artistCountry}/>
                )
            });
        }
    }

    handleCloseClicked() {
        const _this = this;
        const currentKey = this.state.sliderKey;
        this.setState({
            entries: [],
            sliderKey: currentKey + 1
        }, () => {
            if(_this.props.onClose) {
                _this.props.onClose();
            }
        });
    }

    getSlidesToShowMD() {
        return 1;
    }

    getSlidesToShowLG() {
        return 1;
    }

    handleClickedBackground(e) {
        e.preventDefault();
        e.stopPropagation();
        if(e.target === document.querySelector('.Modal') || e.target === document.querySelector('.slick-list')) {
            this.handleCloseClicked(e);
        }
    }

    handleReinit() {
        const leftArrow = document.querySelector('.slick-prev');
        if(leftArrow) {
            leftArrow.style.display = this.state.currentIndex > 0 ? 'block' : 'none';
        }
    }

    handleSlideChange(index) {
        const leftArrow = document.querySelector('.slick-prev');
        const rightArrow = document.querySelector('.slick-next');

        this.setState({
            currentIndex: index
        });

        if(leftArrow) {
            if(index === 0) {
                leftArrow.style.display = 'none';
            } else {
                leftArrow.style.display = 'block';
            }
        }

        if(index === this.state.entries.length  - 1 && this.props.onPageEnd) {
            this.props.onPageEnd();

            if(rightArrow) {
                rightArrow.style.display = 'none';
            }
        } else {
            if(rightArrow) {
                rightArrow.style.display = 'block';
            }
        }
    }

    handleKeypress(e) {
        if(e.code.toLowerCase().includes('escape') && this.props.isVisible) {
            this.handleCloseClicked();
        }
    }

    componentDidMount() {
        if(this.bg.current) {
            this.bg.current.addEventListener('keydown', this.handleKeypress);
        }
    }

    componentWillUnmount() {
        if(this.bg.current) {
            this.bg.current.removeEventListener('keydown', this.handleKeypress);
        }
    }

    render() {
        const shouldLazyload = !this.props.errorState;
        const settings = {
            className: 'Modal__slider',
            centerMode: true,
            infinite: false,
            slidesToShow: this.getSlidesToShowLG(),
            speed: 500,
            centerPadding: '42%',
            lazyLoad: shouldLazyload,
            adaptiveHeight: false,
            variableWidth: false,
            focusOnSelect: true,
            useTransform: false,
            prevArrow: this.getPrevArrow(),
            nextArrow: this.getNextArrow(),
            swipeToSlide: true,
            mobileFirst: true,
            respondTo: 'slider',
            rows: 1,
            onInit: this.handleInit,
            onReInit: this.handleReinit,
            initialSlide: 0,
            afterChange: this.handleSlideChange,
            responsive: [
                {
                    breakpoint: 1920,
                    settings: {
                        slidesToShow: this.getSlidesToShowMD(),
                        centerPadding: '30%',
                    }
                },
                {
                    breakpoint: 826,
                    settings: {
                        slidesToShow: this.getSlidesToShowMD(),
                        centerPadding: '20%',
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: this.getSlidesToShowMD(),
                        centerPadding: '10%',
                    }
                },
                {
                    breakpoint: 400,
                    settings: {
                        slidesToShow: 1,
                        centerPadding: '6%',
                    }
                }
            ]
        };
        let visibilityClass = this.props.isVisible ? 'Modal__visible' : 'Modal__invisible';
        return (
            <Provider theme={defaultTheme} colorScheme="dark">
                <div className={`Modal ${visibilityClass}`}
                     onClick={this.handleClickedBackground}
                     ref={this.bg}>
                    <Slider key={this.state.sliderKey} {...settings} ref={this.slider}>
                        {this.getImages()}
                    </Slider>
                    <div className="close">
                        <button onClick={this.handleCloseClicked}>
                            <img src={closeIcon}/>
                        </button>
                    </div>
                </div>
            </Provider>
        )
    }
}